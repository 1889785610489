<template>
  <v-app>
<div>
<BreadcrumbComponent :items=items></BreadcrumbComponent>
<PreloaderComponent :enable=enable></PreloaderComponent>

<v-container fluid class="settingscontainer" v-if=enable>
  <v-card
  elevation="2" class="text-right"
>

  <v-card style="margin-top:10px">
  <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="tablesearch"
      ></v-text-field>
    </v-card-title>
  <v-data-table
    :headers="headers"
    :items="rows"
    :items-per-page="10"
    class="elevation-1"
    :search="search"
  >
    <template v-slot:item.status="{ item }">
      <v-chip
        :class="getStatusColor(item.status)"
      >
        {{ getStatusLabel(item.status) }}
      </v-chip>
    </template>
    <template v-slot:item.amount="{ item }">
        {{ getAmount(item.amount) | formatNumber }}
    </template>
    <template v-slot:item.created_at="{ item }">
        {{ getFormatedDate(item.created_at) }}
    </template>
    <template v-slot:item.id="{ item }">
        {{ getRenewalNumber(item.id) }}
    </template>
    <template slot="body.append">
      <tr>
          <th>TOTAL</th>
          <th></th>
          <th></th>
          <th></th>
          <th>{{ 0 | formatNumber }} HTG</th>
          <th></th>
          <th></th>
          <th></th>
      </tr>
  </template>
  </v-data-table>
</v-card>

</v-card>
<p style="padding:10px;border-radius:3px;background:#d9edf7; border:1px solid #c7e2f0;text-align:left;color:black;margin-top:30px">
  Ce rapport montre toutes les tentatives de renouvellement, ainsi que celles qui ont été validés, faites soit sur l'application Android, soit dans la page "Appareils" sur l'application Web. Notez que les transactions en attentes peuvent devenir nombreuses et sont donc supprimés 30 jours après la date de création. Si vous avez des questions concernant une transaction en attente (ex: compte débité mais date du prochain renouvellement pas mis à jour), assurez-vous de nous contacter dans les 30 jours de validité de la transaction en attente. 
</p>
</v-container>
</div>
</v-app>
</template>

<script>
import BreadcrumbComponent from '../components/BreadcrumbComponent.vue'
import PreloaderComponent from '../components/PreloaderComponent.vue'
import moment from 'moment'

export default {
  name: 'RenewalsView',
  methods: {
    load: function(){
      this.business_id = JSON.parse(localStorage.business).id
      var self = this;
      this.$axios.get('/renewals?include=device,business&orderdesc=renewal_date&business_id='+this.business_id+'&from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString())
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        })
    },
    changedate(){
      var self = this;
      this.enable = false;
      this.$axios.get('/renewals?include=device,business&orderdesc=renewal_date&status='+this.status+'&from='+localStorage.debut_periode.toLocaleString()+'&to='+localStorage.fin_periode.toLocaleString()+'&business_id='+this.business_id)
        .then(function (response) {
          self.rows = response.data
          self.enable = true          
        })
    },
    getStatusColor: function(status){
        var color = "class_yellow text-right"
        if(status){
          color = "class_green text-right"
        }
        return color
      },
    getFormatedDate: function(date){
        return moment(date).format("YYYY-MM-DD")
      },
    getStatusLabel: function(status){
      var label = "EN ATTENTE"
      if(status){
        label = "VALIDE"
      }
      return label
    },
    getAmount: function(amount){
        return amount+" HTG"
    },
    getRenewalNumber: function(id){
      var renewal_number = 10000 + parseInt(id)
      return renewal_number
    },
    total() {
        var total = 0
        for (var i = this.rows.length - 1; i >= 0; i--) {
            total = total + parseInt(this.rows[i]['amount'])
        }
        return total
    }
  },
  mounted(){
    this.load()
  },
  data: () => ({
      search: '',
      state: {
        from: new Date(),
        to: new Date(),
      },
      business_id : '',
      status : '',
      dialog:false,
      loading: false,
      statuses: [{name: "Validé", id: 1}, {name: "En Attente", id: 0}, {name: 'Annulé', id: 2}],
      index: -1,
      items: [
        {
          text: 'Renouvellements',
          disabled: false,
          href: '/renewals',
        }
      ],
      fluid: true,
      enable: false,
      headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: 'id',
          },
          { text: 'APPAREIL', value: 'device.name' },
          { text: 'CREE LE', value: 'created_at' },
          { text: 'DUE LE', value: 'renewal_date' },
          { text: 'MONTANT', value: 'amount' },
          { text: 'PROCHAIN RENOUVELLEMENT', value: 'paid_until' },
          { text: 'MEMO', value: 'memo' },
          { text: 'STATUT', value: 'status' },
        ],
        rows: [],
    }),
  components: { BreadcrumbComponent, PreloaderComponent },
}
</script>

<style type="text/css">
  .text-right{
    text-align:right;
  }

  .dateinput{
    border:1px solid #ddd;
    padding:5px;
  }
  .theme--dark.v-btn.v-btn--has-bg{
    background-color:#003F74 !important;
  }

  .settingscontainer{
    margin-top:10px!important;
  }
  .v-application a{
    color:black!important;
  }
  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  th.text-start, td.text-start{
    text-align:center!important;
  }
  th.text-start:first-child, td.text-start:first-child{
    text-align:left!important;
  }
  th.text-start:last-child, td.text-start:last-child{
    text-align:right!important;
  }
  .class_green{
   background:#4caf50 !important;
   color:white!important;
  }
  .class_red, .confirm_delete_button{
   background:#ff5252 !important;
   color:white!important;
  }
  .uppercase{
    text-transform: uppercase;
  }

  .text-center{
    text-align:center;
  }

  .tablesearch .v-input__slot:before{
    display:none;
  }

  .filterrow{
    height:60px;
  }

  .tablesearch .v-input__control{
    border: 1px solid #ddd;
    padding: 5px 10px;
  }
  .edit_button{
    font-size: 16px;
    padding: 5px;
    background: #003F74 !important;
    color: white!important;
    border-radius: 4px;
  }
  .delete_button{
    background:#ff5252 !important;
    color: white!important;
    border-radius: 4px;
    font-size: 16px;
    padding: 5px;
  }
  .v-application .justify-center{
    justify-content: right!important;
  }
  .v-application .v-btn{
    width:70px!important;
    margin-right:22px;
    margin-top:-8px;
  }
  .v-dialog .v-card__title{
    background:#f3f3f3;
    border-bottom:1px solid #ddd;
  }
  .v-btn.close_modal_button{
    background-color:#1976d2!important;
    color:white!important;
  }
  .v-btn.confirm_modal_button{
    background-color:#4caf50!important;
    color:white!important;
  }

  .v-dialog .v-card__actions{
    padding-bottom: 15px!important;
    padding-top: 20px!important;
    background: #f3f3f3;
    border-top: 1px solid #ddd;
  }

  .color-red{
    color:red!important;
  }
  .class_yellow{
    background:#f0ad4e!important;
    color:white!important;
  }
  .has_errors{
    padding-top:0px;
    margin-top:0px;
  }

  .v-card__actions .v-progress-circular{
    margin-top:1px !important;
  }
    .v-card__actions .v-progress-circular circle{
      color:white!important;
    }
    .v-text-field--outlined > .v-input__control > .v-input__slot {
    align-items: stretch;
    min-height: 0px!important;
    height: 37px;
    border-radius: 0px;
}
.v-text-field--enclosed .v-input__append-inner{
margin-top:7px!important;
}
.v-text-field--outlined .v-label{
  margin-top:-8px!important;
}
</style>