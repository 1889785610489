var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('div',[_c('BreadcrumbComponent',{attrs:{"items":_vm.items}}),_c('PreloaderComponent',{attrs:{"enable":_vm.enable}}),(_vm.enable)?_c('v-container',{staticClass:"settingscontainer",attrs:{"fluid":""}},[_c('v-card',{staticClass:"text-right",attrs:{"elevation":"2"}},[_c('v-card',{staticStyle:{"margin-top":"10px"}},[_c('v-card-title',[_c('v-text-field',{staticClass:"tablesearch",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.rows,"items-per-page":10,"search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:_vm.getStatusColor(item.status)},[_vm._v(" "+_vm._s(_vm.getStatusLabel(item.status))+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.getAmount(item.amount)))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormatedDate(item.created_at))+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRenewalNumber(item.id))+" ")]}}],null,false,3377408876)},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("TOTAL")]),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm._f("formatNumber")(0))+" HTG")]),_c('th'),_c('th'),_c('th')])])],2)],1)],1),_c('p',{staticStyle:{"padding":"10px","border-radius":"3px","background":"#d9edf7","border":"1px solid #c7e2f0","text-align":"left","color":"black","margin-top":"30px"}},[_vm._v(" Ce rapport montre toutes les tentatives de renouvellement, ainsi que celles qui ont été validés, faites soit sur l'application Android, soit dans la page \"Appareils\" sur l'application Web. Notez que les transactions en attentes peuvent devenir nombreuses et sont donc supprimés 30 jours après la date de création. Si vous avez des questions concernant une transaction en attente (ex: compte débité mais date du prochain renouvellement pas mis à jour), assurez-vous de nous contacter dans les 30 jours de validité de la transaction en attente. ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }